import { environment as env } from "src/environments/environment";
import { AppRegion, UrlProtocol } from "src/environments/environment.types";
import { ConfigType } from './api/requests/config';
import { Color } from './app-colors';

export class AppSettings {
    /** Jeigu vartotojams pateikiama nuoroda į "manual", tai ją reiktų įrašyti čia. Tai turi būti pilnas URL. */
    // nes beta2 nėra public static manualUrl = "";
    /** Rodo "Log" mygtuką bei vykdo veiksmų loginimą. Naudinga tik beta testavimams. Production turi būti "false" */
    public static showLogger = true;
    /** Nurodo ar vykdyti loginimą į "console" */
    public static logToConsole = true;
    /** Web aplikacijos pavadinimas, kuris bus rodomas naršyklių tab'e. pvz "Protegus" */
    public static title = "Protegus2 Beta";
    /** Fizinis įmonės adresas */
    public static address = "Draugystės g. 17, LT-51229 Kaunas, Lietuva";
    /** Įmonės email, kuriuo galima susisiekti */
    public static email = "support@trikdis.lt";
    /** Įmonės telefonas, kuriuo galima paskambinti */
    public static phone = "+370 37 408040";
    /** Įmonės web puslapis (tai nėra web app!) */
    public static companyUrl = "trikdis.com";
    /** Nurodo ar rodyti google login mygtuka */
    public static googleLoginSupported = true;
    /** Nurodo ar rodyti apple login mygtuka */
    public static appleLoginSupported = true;
    /** Nurodo ar soniniame meniu bus matoma Help dalis */
    public static showHelp = true;
    /** Nurodo ar rodyti Advanced meniu */
    public static showConfig = true;
    /** Nurodo ar reikalingas regioninis peradresavimas */
    public static regionsEnabled = true;
    /** Palaikomi regionai */
    public static regions: AppRegion[] = [
      { id: 'ohi', backEndHost: "ohi.protegus.app", port: "", protocol: UrlProtocol.https, gmtOffsetStart: -660, gmtOffsetEnd: -120 },
      { id: "b2", backEndHost: "api.beta2.protegus.app", port: "", protocol: UrlProtocol.https, gmtOffsetStart: -119, gmtOffsetEnd: -659, },
    ];
    /** Nurodo ar galima kurti vartotojus, kurie dar neegzistuoja */
    public static canAutoCreateAccounts = true;
    /** Nurodo ar super adminui reikia krauti pilną vartotojų sąrašą */
    public static loadAllUsersForSU = false;
    /** Nurodo ar super adminui reikia krauti pilną sistemų sąrašą */
    public static loadAllSystemsForSU = true;
    /** Nurodo ar tik Super admin gali pridėti/ištrinti vartotojus. Esant false tą gali daryti visi. */
    public static onlySUcanAddRemoveUsers = false;
    /** Nurodo ar galima trinti sistemą esant sistemų sąraše */
    public static canDeleteSystemFromList = false;
    /** Nurodo ar galima trinti vartotojus esant vartotojų sąraše */
    public static canDeleteUserFromList = false;
    /** Nurodo ar turi veikti ribojimų sistema. (Kamerų kiekis, sistemų kiekis ir t.t.) */
    public static limitationSystemEnabled = true;
    /** Nurodo ar reikia rodyti versiją */
    public static showAppVersion = true;
    public static appVersionMajor = 2;
    public static appVersionMinor = 6;
    public static appVersionBuild = 982;
    public static appVersionDate = 1737713441;
    /** Nurodo ar super admin gali peradresuoti modulius tarp regionų */
    public static deviceTransferEnabled = true;
    /** Nurodo ar vartotojai gali peradresuoti modulius tarp v1 ir v2 versijų */
    public static deviceTransferToAppEnabled = true;
    public static primaryColor: Color = "#0070A7";
    public static secondaryColor: Color = "#EF476F";
    /** Nurodo kokį pavadinimą reikės rodyti Wifi GV moduliams */
    public static wifiGateDevice37Name: "WP17" | "ZEM-CWI" | "AP-114" = "WP17";
    /** sentry glitch */
    public static sentry = null; 
//    {
//      dsn: "https://c828c95628aa41aeb6355d4854547fc4@app.glitchtip.com/2418",
//      environment: "beta2",
//    };
    public static appCopyright = 'UAB Trikdis Copyright &reg; Trikdis EU 018535771 Protegus &reg';
    /** Nurodo ar įkompiliuojamas company desktop */
    public static companyDesktopEnabled = true;
    public static companyRegistrationSupported = true;
    public static googleClientIdWeb = "329407298396-7p01hkk3f6hq0iu2gcpvs4oi6o5d6n31.apps.googleusercontent.com";
    /** Google reCAPTCHA siteKey */
    public static greSiteKey = "";
    /** Pasakom ar siusti js error į api serveri */
    public static localReport = true;
    public static readonly production = false;
    public static readonly configTypeOverrides: { type: ConfigType; override: string }[] = [];
    public static readonly companyPaymentsEnabled = true;
    public static readonly stripePublicKey = 'pk_test_51P4ILzJ29srNYoVHaciAgNARLXcVlzIawzhzaFlUBBUJGWgaO54zvvZ0PjsDKbheNTEc1nX4kpPtfEOHxvhzJMnW00MFIFudL1';
    public static readonly stripeSecretKey = 'sk_test_51P4ILzJ29srNYoVHgOitxiQyvUfeYIMRw1GpQBDaCUbKMreHcbqusGE7OR3nnNLBt1Uqy3YHxpM7lqXDvOyexFtD00hP4SR6wC';
    /* VAT ID serverio savininko. Kitaip sakant Trikdzio */
    public static readonly taxId = 'LT347641610';
    public static readonly canChangeSystemImei = true;
}

export const environment = AppSettings;
