import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformService } from 'src/app/api/platform.service';
import { SlideoutOptions } from 'src/app/models/slideout-options';
import { LanguageService } from 'src/app/services/language.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { PopupField, PopupOptions } from '../../models/popup-options';
import { PopupType } from '../../models/popup-type';
import { PopupService } from '../../services/popup.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ToggleButtonComponent } from 'src/app/components/toggle-button/toggle-button.component';
import { AutofocusDirective } from 'src/app/directives/autofocus.directive';
import { ButtonComponent } from 'src/app/ui/button/button.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { CheckboxComponent } from 'src/app/general/checkbox/checkbox.component';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('showSlideout', [
      state('hidden', style({ opacity: 0, transform: 'translateY(100%)' })),
      state('visible', style({ opacity: 1, transform: 'translateY(0px)' })),
      transition('hidden => visible', animate('0.15s linear')),
    ]),
  ],
  standalone: true,
  imports: [CommonModule, FormsModule, ToggleButtonComponent, AutofocusDirective, ButtonComponent, CheckboxComponent],
})
export class PopupComponent implements OnInit, OnDestroy {
  private hexKeys = '0123456789abcdefABCDEF';
  private numKeys = '0123456789';
  private letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  private goodKeys = [
    8, // backspace
    37, // <-
    39, // ->
    46, // delete
  ];
  public timeZoneHours = [
    '-12',
    '-11',
    '-10',
    '-9',
    '-8',
    '-7',
    '-6',
    '-5',
    '-4',
    '-3',
    '-2',
    '-1',
    '+0',
    '+1',
    '+2',
    '+3',
    '+4',
    '+5',
    '+6',
    '+7',
    '+8',
    '+9',
    '+10',
    '+11',
    '+12',
  ];
  public currentPopup = PopupType.None;
  public currentSlideout = PopupType.None;
  public PopupType = PopupType;
  private subscribers = {
    popup: {
      show: null,
      hide: null,
    },
    slideOut: {
      show: null,
      hide: null,
    },
    activeSystem: null,
  };
  public popupOptions: PopupOptions = null;
  public slideOutOptions: SlideoutOptions<any> = null;
  public popupVisible = false;
  public slideOutVisible = false;
  public slideoutState = 'hidden';

  public passwordInClearText = false;

  public field1Value: { val: string } = { val: '' };
  public field2Value: { val: string } = { val: '' };
  public field3Value: { val: string } = { val: '' };
  public toggleValue;
  public field1IsFocused = false;
  public field2IsFocused = false;
  public field3IsFocused = false;
  public timeZoneHourValue = '';
  private routerEventsSubscribtion: Subscription;
  public activeDropdownValues = new Set<string | number>();

  constructor(
    private pp: PopupService,
    public l: LanguageService,
    public platform: PlatformService,
    router: Router,
  ) {
    this.subscribers.popup.show = this.pp.onShowPopup.subscribe((opt) => {
      this.showPopup(opt.options, opt.popupType);
    });
    this.subscribers.popup.hide = this.pp.onHidePopup.subscribe(() => {
      this.hidePopup();
    });
    this.subscribers.slideOut.show = this.pp.onShowSlideout.subscribe((opt) => {
      this.showSlideout(opt.options, opt.popupType);
    });
    this.subscribers.slideOut.hide = this.pp.onHideSlideout.subscribe(() => {
      this.hideSlideout();
    });
    this.routerEventsSubscribtion = router.events.subscribe((event) => {
      if ( event instanceof NavigationStart ) {
        this.cancelPopup();
        this.hideSlideout();
      }
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscribers.popup.show.unsubscribe();
    this.subscribers.popup.hide.unsubscribe();
    this.subscribers.slideOut.show.unsubscribe();
    this.subscribers.slideOut.hide.unsubscribe();
    this.routerEventsSubscribtion?.unsubscribe();
  }

  public cancelPopup() {
    if (this.popupOptions === null) {
      return;
    }
    if (this.popupOptions.onCancel === undefined) {
      this.hidePopup();
      return;
    }
    this.popupOptions.onCancel();
    this.hidePopup();
  }

  public submitPopup() {
    if (!this.popupOptions) {
      return;
    }
    if (this.popupOptions.field1?.isHex) {
      this.field1Value.val = this.field1Value.val.toUpperCase();
    }
    if (this.popupOptions.field2?.isHex) {
      this.field2Value.val = this.field2Value.val.toUpperCase();
    }
    if (this.popupOptions.field3?.isHex) {
      this.field3Value.val = this.field3Value.val.toUpperCase();
    }

    if ([PopupType.Password, PopupType.String, PopupType.IPAddress, PopupType.Phone, PopupType.NumericPassword].includes(this.currentPopup)) {
      if (this.currentPopup === PopupType.Phone && this.field1Value.val !== '') {
        this.field1Value.val = '+' + this.field1Value.val;
      }
      const opts = this.popupOptions; // nes hidePopup sunaikins.
      this.hidePopup();
      if (opts.onSubmitString) {
        opts.onSubmitString(this.field1Value.val);
      }
    } else if ([PopupType.Number, PopupType.NumberWithUnitList, PopupType.TimeZone, PopupType.DateYMD].includes(this.currentPopup)) {
      if (this.popupOptions.field1) {
        this.checkForMin(this.field1Value, this.popupOptions.field1);
      }
      if (this.popupOptions.field2) {
        this.checkForMin(this.field2Value, this.popupOptions.field2);
      }
      if (this.popupOptions.field3) {
        this.checkForMin(this.field3Value, this.popupOptions.field3);
      }
      const opts = this.popupOptions; // nes hidePopup sunaikins.
      this.hidePopup();
      if (opts.onSubmit) {
        opts.onSubmit({
          toggle: this.toggleValue,
          value1: opts.field1?.precision ? Number(parseFloat(this.field1Value.val).toFixed(opts.field1.precision)) : parseInt(this.field1Value.val, opts.field1?.isHex ? 16 : 10),
          value2: opts.field2?.precision ? Number(parseFloat(this.field2Value.val).toFixed(opts.field2.precision)) : parseInt(this.field2Value.val, opts.field2?.isHex ? 16 : 10),
          value3: opts.field3?.precision ? Number(parseFloat(this.field3Value.val).toFixed(opts.field3.precision)) : parseInt(this.field3Value.val, opts.field3?.isHex ? 16 : 10),
        });
      }
    }
  }

  private showPopup(options: PopupOptions, type: PopupType) {
    this.field1Value.val = '';
    this.field2Value.val = '';
    this.field3Value.val = '';
    this.popupOptions = options;
    this.currentPopup = type;
    this.popupVisible = true;
    if (options.field1?.oldValue !== undefined) {
      this.field1Value.val = typeof options.field1.oldValue === 'number' ? options.field1.oldValue.toString(options.field1.isHex ? 16 : 10) : options.field1.oldValue;
      if (typeof options.field1.oldValue === 'number' && !options.field1.maxLen && options.field1.maxValue) {
        options.field1.maxLen = options.field1.maxValue.toString(options.field1.isHex ? 16 : 10).length;
      }
      if (options.field1.maxLen && this.field1Value.val.length > options.field1.maxLen) {
        this.field1Value.val = this.field1Value.val.substr(0, options.field1.maxLen);
      }
      if (type === PopupType.Phone && this.field1Value.val.length > 0 && this.field1Value.val[0] === '+') {
        this.field1Value.val = this.field1Value.val.replace('+', '');
      }
    }
    if (options.field2?.oldValue !== undefined) {
      this.field2Value.val = typeof options.field2.oldValue === 'number' ? options.field2.oldValue.toString(options.field2.isHex ? 16 : 10) : options.field2.oldValue;
      if (typeof options.field2.oldValue === 'number' && !options.field2.maxLen && options.field2.maxValue) {
        options.field2.maxLen = options.field2.maxValue.toString(options.field2.isHex ? 16 : 10).length;
      }
    }
    if (options.field3?.oldValue !== undefined) {
      this.field3Value.val = typeof options.field3.oldValue === 'number' ? options.field3.oldValue.toString(options.field3.isHex ? 16 : 10) : options.field3.oldValue;
      if (typeof options.field3.oldValue === 'number' && !options.field3.maxLen && options.field3.maxValue) {
        options.field3.maxLen = options.field3.maxValue.toString(options.field3.isHex ? 16 : 10).length;
      }
    }
    if (options.toggle) {
      this.toggleValue = options.toggle.oldValue;
    }
  }

  private hidePopup() {
    this.currentPopup = PopupType.None;
    this.popupVisible = false;
    this.popupOptions = null;
  }

  private showSlideout(options: any, type: PopupType) {
    this.slideOutVisible = true;
    this.slideOutOptions = options;
    this.currentSlideout = type;
    if(type === PopupType.SlideoutWithCheckbox) {
      this.activeDropdownValues = new Set<string | number>(options.checkedValues);
    }
    setTimeout(() => {
      this.slideoutState = 'visible';
    }, 50);
  }

  private hideSlideout() {
    this.slideOutVisible = false;
    this.slideOutOptions = null;
    this.slideoutState = 'hidden';
  }

  public analyzeInput(event: InputEvent, field: PopupField, whichField: number) {
    if (this.isSymbolGood(event, field, whichField)) {
      return;
    }
    setTimeout(() => {
      if (whichField === 1) {
        const lastCharIndex: number = this.field1Value.val.lastIndexOf(event.data);
        this.field1Value.val = this.field1Value.val.slice(0, lastCharIndex) + this.field1Value.val.slice(lastCharIndex).replace(event.data, '');
      } else if (whichField === 2) {
        const lastCharIndex: number = this.field2Value.val.lastIndexOf(event.data);
        this.field2Value.val = this.field2Value.val.slice(0, lastCharIndex) + this.field2Value.val.slice(lastCharIndex).replace(event.data, '');
      } else if (whichField === 3) {
        const lastCharIndex: number = this.field3Value.val.lastIndexOf(event.data);
        this.field3Value.val = this.field3Value.val.slice(0, lastCharIndex) + this.field3Value.val.slice(lastCharIndex).replace(event.data, '');
      }
    }, 50);
  }

  private isSymbolGood(event: InputEvent, field: PopupField, whichField: number): boolean {
    if (field.isHex && !this.hexKeys.includes(event.data)) {
      if (event.data === null) {
        // del arba backspace
        return true;
      }
      return false;
    }

    if ([PopupType.Number, PopupType.NumberWithUnitList, PopupType.TimeZone, PopupType.Phone, PopupType.NumericPassword, PopupType.DateYMD].includes(this.currentPopup)) {
      if(event.data === '-' && field.minValue < 0) {
        return (whichField === 1 && this.field1Value.val === '-') || (whichField === 2 && this.field2Value.val === '-') || (whichField === 3 && this.field3Value.val === '-')
      }
      if(field.precision) {
        if(event.data === '.') {
          return (whichField === 1 && this.field1Value.val.split('.').length === 2) || (whichField === 2 && this.field2Value.val.split('.').length === 2) || (whichField === 3 && this.field3Value.val.split('.').length === 2);
        } else {
          const valParts = this.field1Value.val.split('.');
          if(valParts.length === 2 && valParts[valParts.length - 1].length > field.precision) {
            return false;
          }
        }
      }
      if (!field.isHex && !this.numKeys.includes(event.data)) {
        if (event.data === null) {
          return true;
        }
        return false;
      }
      let combinedValue = '';
      let fieldToUse;
      if (whichField === 1) {
        combinedValue = this.field1Value.val;
        fieldToUse = this.field1Value;
      } else if (whichField === 2) {
        combinedValue = this.field2Value.val;
        fieldToUse = this.field2Value;
      } else if (whichField === 3) {
        combinedValue = this.field3Value.val;
        fieldToUse = this.field3Value;
      }
      if(field.minValue < 0) {
        this.checkForMin(fieldToUse, field, event);
      }
      this.checkForMax(combinedValue, fieldToUse, event, field);
    } else if (this.currentPopup === PopupType.IPAddress) {
      if (!this.numKeys.includes(event.data) && event.data !== '.' && event.data !== ',') {
        return false;
      }
      if (event.data === ',') {
        this.field1Value.val = this.field1Value.val.replace(',', '.');
      }
      if (this.field1Value.val.length >= 2 && this.field1Value.val[this.field1Value.val.length - 2] === '.' && event.data === '.') {
        return false;
      }
      const ipParts = this.field1Value.val.split('.');
      if (ipParts.length >= 4 && event.data === '.') {
        return false;
      }
    }
    return true;
  }

  public analyzeKeyPressField(event: KeyboardEvent, field: PopupField, whichField: number) {
    if (field.isHex && !this.hexKeys.includes(event.key)) {
      if (this.goodKeys.includes(event.keyCode)) {
        return;
      }
      event.preventDefault();
    } else if (field.lettersOnly !== undefined && field.lettersOnly) {
      if (!this.letters.includes(event.key)) {
        event.preventDefault();
      }
    } else if (field.numbersOnly !== undefined && field.numbersOnly) {
      if (!this.numKeys.includes(event.key) && !this.goodKeys.includes(event.keyCode)) {
        event.preventDefault();
      }
    } else if ([PopupType.Number, PopupType.NumberWithUnitList, PopupType.TimeZone, PopupType.Phone, PopupType.NumericPassword, PopupType.DateYMD].includes(this.currentPopup)) {
      if (!field.isHex && !this.numKeys.includes(event.key)) {
        if (this.goodKeys.includes(event.keyCode)) {
          return;
        }
        event.preventDefault();
        return;
      }
      const position = this.deleteInputSelection(event, whichField);
      let combinedValue = '';
      let fieldToUse;
      if (whichField === 1) {
        combinedValue = this.field1Value.val;
        fieldToUse = this.field1Value;
      } else if (whichField === 2) {
        combinedValue = this.field2Value.val;
        fieldToUse = this.field2Value;
      } else if (whichField === 3) {
        combinedValue = this.field3Value.val;
        fieldToUse = this.field3Value;
      }
      combinedValue = combinedValue.substring(0, position) + event.key + combinedValue.substring(position);
      this.checkForMax(combinedValue, fieldToUse, event, field);
    } else if (this.currentPopup === PopupType.IPAddress) {
      if (!this.numKeys.includes(event.key) && !this.goodKeys.includes(event.keyCode) && event.key !== '.' && event.key !== ',') {
        event.preventDefault();
        return;
      }
      let pressedKey = event.key;
      let addPressedkeyOnSuccess = false;
      if (pressedKey === ',') {
        event.preventDefault();
        pressedKey = '.';
        addPressedkeyOnSuccess = true;
      }
      if (this.field1Value.val[this.field1Value.val.length - 1] === '.' && pressedKey === '.') {
        event.preventDefault();
        return;
      }
      const ipParts = this.field1Value.val.split('.');
      if (ipParts.length >= 4 && pressedKey === '.') {
        event.preventDefault();
        return;
      }

      if (addPressedkeyOnSuccess) {
        const position = this.deleteInputSelection(event, whichField);
        this.field1Value.val = this.field1Value.val + pressedKey;
      }
    }
  }

  private deleteInputSelection(event: KeyboardEvent, whichField: number): number {
    const target = event.target instanceof HTMLInputElement ? event.target : undefined;
    if ( !target ) { return -1; }
    const selectionLength = target.selectionEnd - target.selectionStart;
    if ( selectionLength === 0 ) { return target.selectionStart; }
    let fieldToUse: {val: string};
    if (whichField === 1) {
      fieldToUse = this.field1Value;
    } else if (whichField === 2) {
      fieldToUse = this.field2Value;
    } else if (whichField === 3) {
      fieldToUse = this.field3Value;
    }
    fieldToUse.val = fieldToUse.val.substring(0, target.selectionStart) + fieldToUse.val.substring(target.selectionEnd);
    return target.selectionStart;
  }

  private checkForMin(currentValueField: { val: string }, field: PopupField, event?) {
    if (currentValueField.val === '') {
      if (field.minValue === undefined) {
        throw new Error('Tuščia reikšmė, o minValue nenurodytas!');
      } else {
        currentValueField.val = field.minValue.toString();
      }
      return;
    }
    if (field.minValue === undefined) {
      return;
    }
    let intValue = field.precision ? parseFloat(currentValueField.val) : parseInt(currentValueField.val, field.isHex ? 16 : 10);
    if (intValue >= field.minValue) {
      return;
    }

    intValue = field.minValue;
    setTimeout(() => {
      currentValueField.val = intValue.toString(field.isHex && !field.precision ? 16 : 10);
    }, 50);
    if (event) {
      event.preventDefault();
    }
  }

  private checkForMax(newValue: string, currentValueField: { val: string }, event, field: PopupField): boolean {
    if (newValue === '' || field.maxValue === undefined) {
      return false;
    }
    let intValue = field.precision ? parseFloat(newValue) : parseInt(newValue, field.isHex ? 16 : 10);
    if (intValue <= field.maxValue) {
      return false;
    }

    intValue = field.maxValue;
    setTimeout(() => {
      currentValueField.val = intValue.toString(field.isHex && !field.precision ? 16 : 10);
    }, 50);
    if (event) {
      event.preventDefault();
    }
    return true;
  }

  public submitSlideout(selection: number) {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onSubmit === undefined) {
      this.hideSlideout();
      return;
    }
    const submitCallback = this.slideOutOptions.onSubmit;
    this.hideSlideout();
    submitCallback(selection);
  }

  public submitSlideoutValue(selection: DropdownItem<string|number>) {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onSubmit === undefined) {
      this.hideSlideout();
      return;
    }
    const submitCallback = this.slideOutOptions.onSubmit;
    this.hideSlideout();
    submitCallback(selection);
  }

  public onCheckboxValueClick(selection: DropdownItem<string|number>) {
    if(selection.value === -1) {
      this.activeDropdownValues.clear();
      this.activeDropdownValues.add(selection.value);
      return;
    }
    if(this.activeDropdownValues.has(selection.value)) {
      this.activeDropdownValues.delete(selection.value);
      if(this.activeDropdownValues.size === 0) {
        this.activeDropdownValues.add(-1);
      }
    } else {
      if(this.activeDropdownValues.has(-1)) {
        this.activeDropdownValues.delete(-1);
      }
      this.activeDropdownValues.add(selection.value);
    }
  }

  public onCheckboxSubmit() {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onSubmitCheckbox === undefined) {
      this.hideSlideout();
      return;
    }
    const submitCallback = this.slideOutOptions.onSubmitCheckbox;
    this.hideSlideout();
    submitCallback(Array.from(this.activeDropdownValues) as string[] | number[]);
  }

  public cancelSlideout() {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onCancel === undefined) {
      this.hideSlideout();
      return;
    }
    this.slideOutOptions.onCancel();
    this.hideSlideout();
  }

  public selectTimeZoneHour() {
    this.pp.showSlideout(
      {
        headerText: this.l.get('configurators.labels.timeZone'),
        items: this.timeZoneHours,
        onSubmit: (res) => {
          this.field1Value.val = res.toString();
        },
      },
      PopupType.Slideout
    );
  }
}
