/* eslint-disable no-shadow */
/* eslint-disable id-blacklist */
export enum PopupType {
  None,
  /**
   * callback: onSubmitString
   */
  Password,
  /**
   * callback: onSubmitString
   */
  String,
  /**
   * callback: onSubmit
   */
  Number,
  /**
   * callback: onSubmit
   */
  NumberWithUnitList,
  /**
   * callback: onSubmit
   */
  TimeZone,
  /**
   * callback: onSubmitString
   */
  IPAddress,
  /**
   * callback: onSubmit
   */
  Slideout,
  /**
   * callback: onSubmit
   */
  SlideoutWithValue,
  /**
   * callback: onSubmit
   */
  SlideoutWithFunctionValue,
  /**
   * callback: onSubmitString
   */
  Phone,
  /**
   * callback: onSubmitString
   */
  NumericPassword,
  /**
   * callback: onSubmit
   */
  DateYMD,
  /**
   * callback: onSubmitCheckbox
   */
  SlideoutWithCheckbox,
}
