import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AreaService } from 'src/app/services/area.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { LanguageAware } from 'src/app/general/language-aware';
import { UResolvable } from 'src/app/guards/UResolvable';
import { LocatorService } from 'src/app/services/locator.service';
import { SystemService } from 'src/app/api/system/system.service';
import { DeviceService } from 'src/app/services/device.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from 'src/app/ui/back-button/back-button.component';

@Component({
  selector: 'app-company-mobile-host',
  templateUrl: './company-mobile-host.component.html',
  styleUrls: ['./company-mobile-host.component.scss'],
  standalone: true,
  imports: [CommonModule, BackButtonComponent, RouterOutlet],
})
export class CompanyMobileHostComponent extends LanguageAware implements OnInit, OnDestroy, UResolvable<typeof CompanyMobileHostComponent> {
  public readonly TBackgroundType = TBackgroundType;
  public isDesktopRoleSimpleUser = this.headerBar.isDesktopRoleSimpleUser;
  public tag = 'CompanyMobileHostComponent';
  public isOnSystemHome: boolean;
  public isOnGradientBackgroundPage: boolean;
  private gradientBackgroundPageRoutes = ['home-configuration'];
  private currentSystemId: number;
  private routeParamsSubscription = this.route.paramMap.subscribe((params) => {
    const deviceId = parseInt(params.get('deviceId'), 10);
    const systemId = parseInt(params.get('systemId'), 10);
    this.currentSystemId = isNaN(deviceId) ? systemId : deviceId;
    this.systemService.changeSystemViewId(this.currentSystemId);
  });
  private routeSubscription = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      const route = this.router.url;
      this.isOnSystemHome = ('/' + this.g.getHomeUrl().filter(r => r !== '/').join('/')) === route;
      this.isOnGradientBackgroundPage = this.gradientBackgroundPageRoutes.includes(route[route.length - 1]);
    }
  });
  public backgroundClass = '';
  public backgroundType = TBackgroundType.White;
  private backgroundSubscription: Subscription;

  private get bgStart() {
    return this.systems.activeSystem?.theme.startColor;
  }
  private get bgEnd() {
    return this.systems.activeSystem?.theme.endColor;
  }
  public get bgFull() {
    return this.systems.activeSystem?.theme.fullBackground;
  }
  public get backgroundStyle() {
    return {
      '--home-background-start': this.bgStart,
      '--home-background-end': this.bgEnd,
      '--full-background': this.bgFull,
    };
  }

  constructor(
    cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    public areaService: AreaService,
    private router: Router,
  ) {
    super(cdRef);
    this.log('+');
    const routeArray = this.router.url.split('/');
    this.isOnSystemHome = routeArray[routeArray.length - 1] === 'home' ? true : false;
    this.currentSystemId = parseInt(this.route.snapshot.paramMap.get('systemId'), 10);
    if( this.us.currentUser && (this.us.currentUser.permissions?.permissions.systems.view || this.us.currentUser.permissions.permissions.unassigned_devices.view) ) { this.headerBar.isDesktopRoleSimpleUser = false; }
    this.handleBackgroundType(this.background.currentType);
    this.backgroundSubscription = this.background.onBackgroundChange.subscribe(newType => {
      this.log('Naujas background', newType);
      this.handleBackgroundType(newType);
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.routeParamsSubscription.unsubscribe();
    this.systemService.changeSystemViewId(-1);
    this.backgroundSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.cdRef.detectChanges();
  }

  public contentIsScrolling(e) {
    const elementHeight = (e.target as HTMLElement).clientHeight;
    const elementInnerHeight = (e.target as HTMLElement).scrollHeight;
    const scrolledSoFar = (e.target as HTMLElement).scrollTop;
    if (elementHeight + scrolledSoFar >= elementInnerHeight - 50) {
      this.g.onScrolledToBottomSource.next();
    }
  }

  public static async resolve(route: ActivatedRouteSnapshot) {
    const deviceId = Number(route.params.deviceId);
    if (deviceId && !isNaN(deviceId)) {
      const systemService = LocatorService.injector.get(SystemService);
      let device = systemService.systemsWithDevices.find(d => d.registration_id === deviceId && d.system_id === null);
      if ( !device ) {
        device = systemService.filteredSystemsWithDevices.find(d => d.registration_id === deviceId && d.system_id === null);
      }
      if ( !device ) { return; }
      (LocatorService.injector.get(DeviceService)).deviceData = {
        adminCode: '123456',
        hwId: 0,
        ipcom: 0,
        mpass: '123456',
        uid: device.imei,
        id: device.registration_id,
      };
      localStorage.setItem('ref', 'device-information');
    }
  }

  public backButtonPressed() {
    this.systems.setCurrentSystem(null);
    history.back();
  }

  private handleBackgroundType(type: TBackgroundType) {
    this.backgroundType = type;
    
    switch (type) {
      case TBackgroundType.Gradient:
        this.backgroundClass = this.bgFull === '' ? 'bgStartEnd': 'bgFull';
        break;
      case TBackgroundType.White:
        this.backgroundClass = 'bg-white';
        break;
      case TBackgroundType.Gray:
        this.backgroundClass = 'bg-gray';
        break;
    }
  }
}
