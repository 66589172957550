import { Injectable, OnDestroy } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import requests from 'src/api/v3/requests';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { EventFilterCriteria, ProcessesdEventFilterCriteria, TEventData } from 'src/app/models/event-data';
import { AuthService } from '../auth.service';
import { TagService } from '../tag.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { EventsService } from 'src/app/services/events.service';

const ProccessCriteria = (criteria: EventFilterCriteria) => ({
  ...criteria,
  startDate: criteria.startDate ? DateTime.fromISO(criteria.startDate) : undefined,
  endDate: criteria.endDate ? DateTime.fromISO(criteria.endDate) : undefined,
});

const DoesEventMatchCriteria = (event: TEventData, criteria: ProcessesdEventFilterCriteria) => {
  if (criteria.systemId && criteria.systemId !== -1 && event.systemId !== criteria.systemId) { return false; }
  const eventTime = DateTime.fromSeconds(event.time);
  if (criteria.reactionIds && !criteria.reactionIds.includes(event.reaction)) { return false; }
  if (criteria.startDate && eventTime < criteria.startDate) { return false; }
  if (criteria.endDate && eventTime > criteria.endDate) { return false; }
  return true;
};

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  public readonly events = new Map<number, TEventData>();
  public readonly systemEvents = new Map<number, Set<number>>();

  private _onEventsChanged = new Subject<void>();
  public readonly onEventsChanged = this._onEventsChanged.pipe(filter(() => !this.suspendEventChange));

  private _onNewEvent = new Subject<TEventData>();

  public getOnNewEvent(criteria?: EventFilterCriteria) {
    if (criteria) {
      const processedCriteria = ProccessCriteria(criteria);
      return this._onNewEvent.pipe(filter((event) => DoesEventMatchCriteria(event, processedCriteria)));
    }
    return this._onNewEvent.asObservable();
  }

  private suspendEventChange = false;

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.events.clear();
    this.systemEvents.clear();
    this._onEventsChanged.next();
  });

  private rtcSubscribtion = this.ws.onEventReceived.subscribe(async (event) => {
    this.ingestEvent(event, event.systemId);
  });
  constructor(
    private auth: AuthService,
    private ws: WebSocketService,
    private es: EventsService,
  ) {}

  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
    this.rtcSubscribtion.unsubscribe();
  }

  public ingestEvent(event?: TEventData | null, system_id?: number): TEventData | undefined {
    if (!event) { return; }

    const isNew = !this.events.has(event.id);
    if ( event.systemId !== system_id ) {
      event.systemId = system_id;
    }
    this.events.set(event.id, event);
    if (!this.systemEvents.has(system_id)) {
      this.systemEvents.set(system_id, new Set());
    }
    event.tags?.forEach((t) => { t.textColor = TagService.getTextColor(t.color); });
    this.systemEvents.get(system_id)?.add(event.id);
    this._onEventsChanged.next();
    if (isNew) { this._onNewEvent.next(event); }
    return event;
  }

  public ingestEventData(event?: TEventData) {
    if (!event) { return; }

    const isNew = !this.events.has(event.id);
    this.events.set(event.id, event);
    if (!this.systemEvents.has(event.systemId)) {
      this.systemEvents.set(event.systemId, new Set());
    }
    event.tags?.forEach((t) => { t.textColor = TagService.getTextColor(t.color); });
    this.systemEvents.get(event.systemId)?.add(event.id);
    this._onEventsChanged.next();
    if (isNew) { this._onNewEvent.next(event); }
  }

  public async getEvents(offset?: number, criteria?: EventFilterCriteria) {
    const reactions = criteria?.reactionIds && criteria?.reactionIds.length ? criteria.reactionIds : undefined;
    return await requests.system.events.getAllSystemsEvents({
      offsetCount: offset,
      date_from: criteria?.startDate,
      date_to: criteria?.endDate,
      system: criteria?.systemId,
      event_desc_id: criteria?.eventDescId,
      user_id: criteria?.userId,
      tag_id: criteria?.tagId,
      reactions })
    .pipe(
      map((result) => {
        if (result.success) {
          this.suspendEventChange = true;
          result.events.map(({ system_id, ...event }) => this.ingestEvent(this.es.convertFromRaw(event), system_id));
          this.suspendEventChange = false;
          this._onEventsChanged.next();
        }
        return this.allEventsSortedByTime;
      })
    ).toPromise();
  }

  public getEventsGetter(criteria?: EventFilterCriteria): DataTableGetter<TEventData> {
    if (criteria) {
      let loadedByCriteria = 0;
      return async (current, columns, more) => {
        if (!more && current <= loadedByCriteria && loadedByCriteria > 0) {
          return this.filterEventsByCriteria(criteria);
        }
        if(loadedByCriteria === 0) {
          this.events.clear();
          this.systemEvents.clear();
         }
        const loadedEvents = await this.getEvents(loadedByCriteria, criteria);
        loadedByCriteria = loadedEvents.length;
        return loadedEvents;
      };
    }
    let loaded = 0;
    return async (current, columns, more) => {
      if (!more && current <= loaded) {
        return this.allEventsSortedByTime;
      }
      const loadedEvents = await this.getEvents(loaded);
      loaded = loadedEvents.length;
      loadedEvents.forEach((e) => {
        e.tags?.forEach((t) => { t.textColor = TagService.getTextColor(t.color); });
      });
      return loadedEvents;
    };
  }

  private get allEventsSortedByTime() {
    return [...this.events.values()].sort((a, b) => b.time - a.time);
  }

  private filterEventsByCriteria(criteria: EventFilterCriteria): TEventData[] {
    const processedCriteria = ProccessCriteria(criteria);
    return [...this.events.values()].filter((event) => DoesEventMatchCriteria(event, processedCriteria)).sort((a, b) => b.time - a.time);
  }
}
